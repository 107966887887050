<template>
  <div class="w-block">
    <el-table :data="data" stripe border :cell-style="{padding:'0px'}"
      :header-cell-style="{borderBottom:'solid 1px #ccc',backgroundColor:'#f5f5f5',textAlign:'center',padding:'5px 0px'}"
      @selection-change="selectionChange" :row-class-name="rowClassName">
      <slot />
    </el-table>
    <el-pagination background layout="prev,pager,next,->,total" :total="total" :page-size="pageSize" v-if="pageShow"
      :current-page="pageIndex" @current-change="onPageChanged" style="margin-top: 10px" />
  </div>
</template>

<script>
  export default {
    name: "wgrid",
    props: {
      total: { type: Number, default() { return 0 } },
      pageSize: { type: Number, default() { return 12; } },
      pageIndex: { type: Number, default() { return 1; } },
      data: { type: Array, default() { return [] } },
      pageShow: { type: Boolean, default() { return true } },
      // rowClassName:{ type: String, default() { return '' } }
    },
    methods: {
      onPageChanged(pi) {
        this.$emit('page-changed', pi)
      },
      selectionChange(val) {
        this.$emit('selection-change', val)
      },
      rowClassName({ row, rowIndex }){
        // if (row.ROLE == 'EXTERNAL') {
        //   return 'external';
        // }
        return '';
      }
    }
  }
</script>
