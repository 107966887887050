<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-world"> </i> 通用管理 | 班级管理</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.GRADE" @change="filter" clearable placeholder="请选择年级">
                <el-option :label="it.name" :value="it.value" v-for="it in whale.grade.lst" :key="it.value" />
              </el-select>
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
              <el-button type="primary" style="margin-left: 10px;" v-if="!isSort" @click="classSort">保存排序</el-button>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1"
              @page-changed="loadPage">
              <el-table-column prop="ID" label="编号" width="100" align="center" />
              <el-table-column prop="NAME" label="班级名称" width="200" align="center" />
              <el-table-column label="年级" width="80" align="center">
                <template slot-scope="scope">{{whale.grade.getName(scope.row.GRADE)}}</template>
              </el-table-column>
              <el-table-column label="排序" width="120" align="center">
                <template slot-scope="scope">
                  <el-button @click="moveUp(scope.$index)" v-if="scope.$index>0" type="text" size="small"><i
                      class="el-icon-top">上移</i></el-button>
                  <el-button @click="moveDown(scope.$index)" v-if="scope.$index!=grid.ls.length-1" type="text"
                    size="small"><i class="el-icon-bottom">下移</i></el-button>
                </template>
              </el-table-column>
              <el-table-column />
            </wgrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Wgrid from "@/components/wgrid";
  export default {
    name: "index",
    components: { Wgrid },
    data() {
      return {
        grid: {
          sea: {
            PAGE_SIZE: 20,
            PAGE_INDEX: 0,
            ROLE: "",
            KEY: ""
          },
          ls: [],
          total: 0,
          loading: false,
        },
        oldIds: "",
        isSort: true
      }
    },
    created() {
      this.getList();
    },
    methods: {
      filter() {
        this.grid.sea.PAGE_INDEX = 0;
        this.grid.ls = [];
        this.getList();
      },
      loadPage(pi) {
        this.grid.sea.PAGE_INDEX = pi - 1;
        this.getList();
      },
      getList() {
        let self = this;
        self.isSort = true
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/ORG/ClassApi/GetList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;
            self.oldIds = its.map(it => it.ID).join('');
          }
        })
      },
      moveUp(index) {
        if (index > 0) {
          const temp = this.grid.ls[index - 1];
          this.$set(this.grid.ls, index - 1, this.grid.ls[index]);
          this.$set(this.grid.ls, index, temp);

          let newIds = this.grid.ls.map(it => it.ID).join('')
          this.isSort = this.oldIds == newIds;
        } else
          this.$message.warning('已经是第一个了')
      },
      moveDown(index) {
        if (index < this.grid.ls.length - 1) {
          const temp = this.grid.ls[index + 1];
          this.$set(this.grid.ls, index + 1, this.grid.ls[index]);
          this.$set(this.grid.ls, index, temp);

          let newIds = this.grid.ls.map(it => it.ID).join('')
          this.isSort = this.oldIds == newIds;
        } else
          this.$message.warning('已经是最后一个了')
      },
      classSort() {
        let list = []
        for (let i = 1; i <= this.grid.ls.length; i++) {
          list.push({
            key: this.grid.ls[i - 1].ID,
            value: i
          })
        }
        let self = this
        self.whale.remote.getResult({
          url: "/api/School/ORG/ClassApi/ClassSort",
          data: { classSorts: JSON.stringify(list) },
          completed: function (res) {
            if (res.DATA) {
              self.$message.success('排序成功')
              self.getList()
            }
          }
        })
      }
    }
  }
</script>